import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import globalVariables from './globalVariable';
import { getAuth } from "firebase/auth";

loadFonts()
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// para validação de campos
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
const firebaseConfig = {  
  apiKey:globalVariables.baseapiKey,
  authDomain:globalVariables.baseauthDomain,
  projectId:globalVariables.baseprojectId,
  storageBucket:globalVariables.basestorageBucket,
  messagingSenderId:globalVariables.basemessagingSenderId,
  appId:globalVariables.baseappId,
  measurementId:globalVariables.basemeasurementId
};


// Initialize Firebase
initializeApp(firebaseConfig);

const app = createApp(App);
  app.use(router);
  app.use(store);
  app.use(vuetify);
  app.mount('#app');

  app.config.globalProperties.$baseUrlApiMain = globalVariables.baseUrlApiMain;

  app.config.globalProperties.$isPermited =  async function(varsis) {           
    const auth = getAuth();
    const u = auth.currentUser;
    const token = localStorage.getItem("token");
    if (!token || token.error=='Token invalid'){
        localStorage.removeItem('token');
        console.log('removido');
        this.token();                
    }
    const req = await fetch(`${this.$baseUrlApiMain}/cadastro/email?email=${u.email}`,{
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
        });
    const datauser = await req.json();   
    console.log(datauser);
    console.log(datauser[0].permissoes[varsis]);

    if (!datauser[0].permissoes[varsis]) {
      this.$store.state.error = true
      router.push('/index')
    }      
};

app.config.globalProperties.$token =  async function() { 

  const auth = getAuth();
  const uu = auth.currentUser;
  const respon = await fetch(`${this.$baseUrlApiMain}/token`,{
  method: "POST",
  headers: { "Content-Type": "application/json" },
  data: { "uid": `${uu.email}`}
  });
  const response = await respon.json()
  console.log('novo index' + response.token);
  localStorage.setItem('token', response.token);
}

  //.use(Vuelidate)


