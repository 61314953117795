import { createStore } from 'vuex'

export default createStore({
  state: {
    drawer: true,
    error:null,
    msg:false,
  },
  getters: {
  },
  mutations: {
    storeInter(state,data){
      state.inter.push(data)
      
    },
  },
  actions: {
  },
  modules: {
  }
})
