<template>
  <v-app>
    <NavigationDrawer v-if="isLoggedIn"></NavigationDrawer>
    <v-main absolute transition="scroll-y-transition">
      <routerView></routerView>
    </v-main>

  </v-app>
</template>


<script >
import NavigationDrawer from "./components/NavigationDrawer.vue";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import router from "@/router";

export default {
  name: 'App',
  mounted() {
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        this.cadastraBd(user)
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    })

  },

  components: {
    NavigationDrawer
  },
  data: () => ({
    auth: getAuth(),
    isLoggedIn: false,
  }),
  methods: {
    signOutUser() {
      const auth = getAuth();
      signOut(auth).then(() => {
        router.push("/");
      }
      )
    },
    async token() {
                const auth = getAuth();
                const uu = auth.currentUser;
                const respon = await fetch(`${this.$baseUrlApiMain}/token`,{
                method: "POST",
                headers: { "Content-Type": "application/json" },
                data: { "uid": `${uu.email}`}
                });
                const response = await respon.json()
                console.log('novo index' + response.token);
                localStorage.setItem('token', response.token);
        },
    async cadastraBd(u) {
      let i = 1;
            const token = localStorage.getItem("token");
            if (!token){
                this.token();                
            }             
      const req = await fetch(`${this.$baseUrlApiMain}/cadastro/email?email=${u.email}`,{
                method: "GET",
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
                });
                // console.log('Passando aqui');
                // console.log(req);
                // console.log('Passou');
                if (req.error=='Token invalid' || req.error=='No token provided'){
                  window.localStorage.removeItem("token");
                  console.log('removido');
                  //this.token();   
                  i++;
                  if (i==2);             
                    this.cadastraBd(u);
            }
      const data = await req.json();
            
      if (!data[0]) {//se não existir
        const req2 = await fetch(`${this.$baseUrlApiMain}/sistema/read`);
        const data2 = await req2.json()
        const permissoes = {};

        for (let i = 0; i < data2.length; i++) {
          const element = data2[i].nomesistema;
          permissoes[`${element}`] = false
        }

        const permissoesJson = JSON.stringify(permissoes)

        const data = {
          nome: u.displayName,
          email: u.email,
          login: true,
          alterar: false,
          permissoes: permissoesJson

        }

        const dataJson = JSON.stringify(data)
        const req = await fetch(`${this.$baseUrlApiMain}/cadastro/create`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dataJson
        });
        await req.json;


      } else {//se ja existir

      }

      /* } else {//se não existir nenhum usuário
        const req2 = await fetch("https://us-central1-api2-seringueira.cloudfunctions.net/app/api/sistema/read");
        const data2 = await req2.json()
        const permissoes = {};

        for (let i = 0; i < data2.length; i++) {
          const element = data2[i].nomeSistema;
          permissoes[`${element}`] = false
        }


        const data = {
          nome: u.displayName,
          email: u.email,
          login: true,
          alterar: false,
          permissoes: permissoes

        }
        const dataJson = JSON.stringify(data)
        const req = await fetch("https://us-central1-api2-seringueira.cloudfunctions.net/app/api/cadastro/create", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: dataJson
        });
        await req.json;
      } */
    },
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>